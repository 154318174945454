const homeContent = {
  hero: {
    title: "Abrir-se para a experimentação",
    subtitle: "extrapola o instaurado e faz com que uma ",
    subtitleEmphasis: "nova realidade se desenhe",
  },
  about: {
    title: "Porque escolher nossos [",
    titleEmphasis: "tentáculos",
    titleEnd: "] ?",
    subtitle: "depois que um de nossos tentáculos atinge você, ",
    subtitleEmphasis: "nada mais será o mesmo.",
    content: {
      title: "tal qual o ",
      titleEmphasis: "bigBang ",
      titleEnd: "()",
      text: [
        "explosiva e transformadora, em 2019 a Tentaculo nasceu. do escuro, do grotesco e da agitação frenética das ideias, surge algo que pode mudar o mundo, suas tecnologias e solucionar seus problemas;",
      ],
    },
    cta: "Conheça a Tentaculo",
  },
  services: {
    title: "O ",
    titleEmphasis: "incômodo ",
    titleEnd: "()",
    subtitle: "é uma estrategia funcional para desenvolver novos caminhos e possibilidades.",
    subtitleEmphasis: "",
    content: {
      title: "concepção = ",
      titleEmphasis: "conclusão ",
      titleEnd: "()",
      text: [
        "somos apaixonados por fazer todo o possível e obter o valor máximo pelo investimento de um cliente em nosso trabalho.",
      ],
    },
    cta: "Conheça os serviços",
  },
  cases: {
    title: "Conheça nossos ",
    titleEmphasis: "cases",
    content: {
      tags: [
        "Disruptivo",
        "Insano",
        "Inquieto",
        "Surreal",
      ],
    },
    cta: "Descubra os cases",
  },
}

export default homeContent;