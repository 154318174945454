import { Link, useParams } from "react-router-dom";

import Footer from "@components/Footer";
import Logo from "@components/Logo";
import Menu from "@components/Menu";

import casePost from "@data/post/case";

const Case = () => {
  const { caseId } = useParams();
  const caseItem = casePost.find((caseItem) => caseItem.pathname === caseId);
  const isEven = (n) => {
    return n % 2 === 0;
  };
  const isOdd = (n) => {
    return n % 2 !== 0;
  };

  return (
    <section data-page="case">
      <Menu />

      <section id="header">
        <figure id="header-background" aria-hidden={true}>
          <img src={caseItem.cover} alt={caseItem.coverTitle} />
        </figure>
        <div className="container">
          <header className="flex justify-content-center">
            <Logo />
          </header>
          <hgroup id="header-title">
            <h1>{caseItem.title}</h1>
            {caseItem.tags && (
              <div className="tags margin-top-40">
                {caseItem.tags.map((tag, index) => (
                  <span key={index}>{tag}</span>
                ))}
              </div>
            )}
          </hgroup>
        </div>
      </section>

      <section className="section padding-vertical-80 padding-vertical-120-tablet">
        <div
          className="section-background gradient-top"
          aria-hidden={true}
        ></div>
        <div className="container">
          <div className="grid gap-24 columns-2-tablet columns-4-laptop">
            {caseItem.info.map((info, index) => (
              <div className="section-content" key={index}>
                <hgroup className="section-title">
                  <h2 className="color-accent">{info.title}</h2>
                </hgroup>
                <ul className="margin-top-24">
                  {info.list.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="section padding-vetical-80 padding-vertical-120-tablet">
        <div className="section-background" aria-hidden={true}></div>
        <div className="container">
          <div className="grid columns-2-laptop gap-40 gap-80-laptop gap-120-laptop-l">
            {caseItem.content.map((content, index) => (
              <article className="section-content" key={index}>
                <hgroup className="section-title">
                  <h2 className="color-accent">{content.title}</h2>
                </hgroup>
                {content.text && (
                  <div className="code margin-top-24">
                    <div className="code-ruler" aria-hidden={true}>
                      <div className="ruler-inline" aria-hidden={true}></div>
                    </div>
                    <div className="code-area">
                      {content.text.map((paragraph, index) => (
                        <p key={index}>{paragraph}</p>
                      ))}
                    </div>
                  </div>
                )}
                {content.list && (
                  <ul className="margin-top-24">
                    {content.list.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                )}
              </article>
            ))}
          </div>
        </div>
      </section>

      <section className="section padding-vertical-80 padding-vetical-120-tablet">
        <div
          className="section-background"
          aria-hidden={true}
        ></div>
        <div className="container">
          <div className="grid columns-5-laptop gap-24 gap-48-laptop">
            <figure
              className="column-span-3-laptop order-1-laptop"
              aria-hidden={true}
            >
              <img src={caseItem.caseImg} alt={caseItem.caseImgTitle} />
            </figure>
            <div className="column-span-2-laptop">
              <hgroup className="section-title">
                <h2 className="color-accent">{caseItem.results.title}</h2>
              </hgroup>
              <article className="section-content margin-top-40">
                <div className="code">
                  <div className="code-ruler" aria-hidden={true}>
                    <div className="ruler-inline" aria-hidden={true}></div>
                  </div>
                  {caseItem.results.text && (
                    <div className="code-area">
                      {caseItem.results.text.map((paragraph, index) => (
                        <p key={index}>{paragraph}</p>
                      ))}
                    </div>
                  )}
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>

      <section className="section padding-vertical-80 padding-vetical-120-tablet">
        <div
          className="section-background gradient-bottom"
          aria-hidden={true}
        ></div>
        <div className="container">
          <Link
            to="/cases"
            className="button button-accent margin-top-56"
          >
            <i
              className="icon icon-angle-left margin-right-4"
              aria-hidden={true}
            />
            <span>Voltar aos cases</span>
          </Link>
        </div>
      </section>

      <Footer />
    </section>
  );
};

export default Case;
