import { Link, NavLink } from "react-router-dom";

import footerData from "../data/components/footer";
import footer from "../data/components/footer";

const Footer = () => {
  return (
    <footer id="footer" className="footer-dark">
      <div className="container">
        <div className="grid columns-2-tablet gap-16 align-center-tablet">
          <div id="footer-content">
            {/* <h4 id="footer-title">{footerData.title}</h4>
            <form id="footer-input" action="">
              <input type="email" placeholder={footerData.placeholder} />
              <button value="Submit" />
            </form> */}
            <ul id="footer-locations">
              {footerData.locations.map((location, index) => (
                <li key={index}>
                  <p>{location}</p>
                </li>
              ))}
            </ul>
            <p id="footer-lead">{footerData.lead}</p>
            <ul id="footer-social">
              {footerData.social.map((social, index) => (
                <li key={index}>
                  <a href={social.to} target="_blank" rel="noopener noreferrer">
                    <i
                      className={`icon icon-brands-${social.icon} icon-width-24`}
                      aria-hidden={true}
                    />
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <nav id="footer-nav">
            {footerData.nav.map((link, index) => (
              <NavLink to={link.to} className="button" key={index}>
                <span>{link.label}</span>
              </NavLink>
            ))}
          </nav>
        </div>
        <div id="footer-copyrights">
          <p>{footerData.copyrights}</p>
          <div className="flex align-center justify-center gap-24 margin-top-24 margin-top-0-tablet">
            <Link to={footer.policy.to} className="button button-small button-accent">
              <i
                className="icon icon-angle-right margin-left-4"
                aria-hidden={true}
              />
              <span>{footerData.policy.label}</span>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
