const clientAmc = `${process.env.assetsDomain}/assets/images/client-amc.svg`;
const clientBpp = `${process.env.assetsDomain}/assets/images/client-bpp.svg`;
const clientFysight = `${process.env.assetsDomain}/assets/images/client-fysight.svg`;
const clientYellowPanda = `${process.env.assetsDomain}/assets/images/client-yellow-panda.svg`;
const clientContaXip = `${process.env.assetsDomain}/assets/images/client-conta-xip.svg`;
const clientNsc = `${process.env.assetsDomain}/assets/images/client-nsc.svg`;
const clientSuma = `${process.env.assetsDomain}/assets/images/client-suma.svg`;
const clientLinkmesh = `${process.env.assetsDomain}/assets/images/client-linkmesh.svg`;

const coverAmcEuqfiz = `${process.env.assetsDomain}/assets/images/cover-amc-euqfiz.jpg`;
const coverBppPix = `${process.env.assetsDomain}/assets/images/cover-bpp-pix.jpg`;
const coverBppSandbox = `${process.env.assetsDomain}/assets/images/cover-bpp-sandbox.jpg`;
const coverFysight = `${process.env.assetsDomain}/assets/images/cover-fysight.jpg`;
const coverYellowPanda = `${process.env.assetsDomain}/assets/images/cover-yellow-panda.jpg`;
const coverContaXip = `${process.env.assetsDomain}/assets/images/cover-conta-xip.jpg`;
const coverNscTotal = `${process.env.assetsDomain}/assets/images/cover-nsc-total.jpg`;
const coverNscClube = `${process.env.assetsDomain}/assets/images/cover-nsc-clube.jpg`;
const coverSuma = `${process.env.assetsDomain}/assets/images/cover-suma.jpg`;
const coverLinkmesh = `${process.env.assetsDomain}/assets/images/cover-linkmesh.jpg`;

const caseAmcEuqfiz = `${process.env.assetsDomain}/assets/images/case-amc-euqfiz.png`;
const caseBppPix = `${process.env.assetsDomain}/assets/images/case-bpp-pix.png`;
const caseBppSandbox = `${process.env.assetsDomain}/assets/images/case-bpp-sandbox.png`;
const caseFysight = `${process.env.assetsDomain}/assets/images/case-fysight-equims.png`;
const caseYellowPanda = `${process.env.assetsDomain}/assets/images/case-yellow-panda.png`;
const caseContaXip = `${process.env.assetsDomain}/assets/images/case-conta-xip.png`;
const caseNscTotal = `${process.env.assetsDomain}/assets/images/case-nsc-total.png`;
const caseNscClube = `${process.env.assetsDomain}/assets/images/case-nsc-clube.png`;
const caseSuma = `${process.env.assetsDomain}/assets/images/case-suma.png`;
const caseLinkmesh = `${process.env.assetsDomain}/assets/images/case-linkmesh.png`;

const casePost = [
  {
    pathname: "aplicativo-de-pontos-e-clube-de-vantagens-nsc",
    title: "Aplicativo de pontos e clube de vantagens",
    excerpt: [
      "Desenvolvemos um aplicativo mobile, juntamente com uma aplicação web PWA, para gerenciar os serviços ofertados pelos parceiros.",
    ],
    tags: ["React Native", "React", "SCSS", "Node"],
    cta: "Veja o case",
    logo: clientNsc,
    logoTitle: "NSC",
    cover: coverNscClube,
    coverTitle: "Case NSC",
    caseImg: caseNscClube,
    caseImgTitle: "Resultado do Case NSC",
    info: [
      {
        title: "Expertise",
        list: [
          "Desenvolvimento Nativo",
          "Frontend",
          "Backend",
          "Testes QA",
        ],
      },
      {
        title: "Setor",
        list: [
          "Gastronomia",
          "Cultura",
          "Lazer"
        ],
      },
      {
        title: "Tipo",
        list: ["App Mobile", "PWA"],
      },
    ],
    content: [
      {
        // figure: "",
        // figureTitle: "",
        title: "O desafio",
        text: [
          "Com a oportunidade de aumentar sua relação com parceiros e clientes finais, a NSC elaborou a ideia de um aplicativo que fosse capaz de gerar pontos e vantagem através de um clube de consumidores e parceiros. Com essa premissa montamos um time com profissionais de UX/UI Design, Análise de Negócio, Desenvolvimento e Arquitetura.",
          "Depois de finalizadas as análises técnicas iniciais, identificamos três produtos nesse projeto: Backoffice capaz de gerenciar os diversos aspectos do negócio, Aplicação PWA que serviria como frente de caixa para os parceiros e um App Mobile para os consumidores.",
        ],
        // list: [],
      },
      {
        // figure: "",
        // figureTitle: "",
        title: "Nossa abordagem",
        // text: [],
        list: [
          "Análise técnica",
          "Criação de protótipos",
          "Desenvolvimento Nativo",
          "Infraestrutura e arquitetura",
          "Implementação e validação",
        ],
      },
    ],
    results: {
      title: "Resultado",
      text: [
        "Junto ao time de produtos da NSC, desenvolvemos um aplicativo mobile para Android e iOS, juntamente com um sistema no formato Web e PWA, capaz de gerenciar os serviços ofertados pelos parceiros, gerando relatórios importantes para o acompanhamento de suas campanhas na aplicação da maneira mais intuitiva e simplificada possível, com a finalidade de tornar a usabilidade o ponto chave do produto.",
        "Não só isso, mas também criar uma relação muito mais afinada entre NSC e seus parceiros e também entre os consumidores e os parceiros NSC. Dessa forma conseguimos criar um ecossistema excelente, tanto para os negócios, como para o desfrute dos consumidores, que agora contam com um clube de vantagens com diversos benefícios.",
      ],
      // list: [],
    },
  },
  {
    pathname: "agregador-de-canais-de-noticias-e-radios-nsc",
    title: "Agregador de canais de notícias e rádios",
    excerpt: [
      "Com consultoria em design, user experience e tecnologia, desenvolvemos um aplicativo mobile com vários aspectos.",
    ],
    tags: ["React Native"],
    cta: "Veja o case",
    logo: clientNsc,
    logoTitle: "NSC",
    cover: coverNscTotal,
    coverTitle: "Case NSC",
    caseImg: caseNscTotal,
    caseImgTitle: "Resultado do Case NSC",
    info: [
      {
        title: "Expertise",
        list: [
          "Desenvolvimento nativo",
          "Frontend",
          "Backend",
          "Testes QA",
        ],
      },
      {
        title: "Setor",
        list: ["Jornalismo"],
      },
      {
        title: "Modelo",
        list: ["App Mobile"],
      },
    ],
    content: [
      {
        // figure: "",
        // figureTitle: "",
        title: "O desafio",
        text: [
          "Quando a NSC nos mostrou seu planejamento para o desenvolvimento do aplicativo NSC Total, percebemos que já havia ali uma estruturação de dados muito bem definida.",
          "Com isso seria necessário fazermos um ótimo aproveitamento dessa base de dados e integrar a ela uma solução que fosse de encontro com as necessidades da NSC.",
          "Foi então que fizemos uma bateria de análises técnicas prudentes ao desenvolvimento do produto e a consultoria em design de interface com foco na experiência do usuário.",
        ],
        // list: [],
      },
      {
        // figure: "",
        // figureTitle: "",
        title: "Nossa abordagem",
        // text: [],
        list: [
          "Análise técnica",
          "Criação de protótipos",
          "Desenvolvimento Nativo",
          "Infraestrutura e arquitetura",
          "Implementação e validação",
        ],
      },
    ],
    results: {
      title: "Resultado",
      text: [
        "O projeto, na modalidade sob demanda, de desenvolvimento do aplicativo da NSC, que é um agregador dos diversos canais de notícia e rádio do grupo NSC, foi sem dúvida uma oportunidade enriquecedora. Nossos time de desenvolvedores participou ativamente nas tomadas de decisões e com isso conseguimos entregar um produto de alta qualidade.",
        "Nossa consultoria em experiência, tecnologia e desenvolvimento, se mostrou muito importante para esse projeto e para o aplicativo mobile Android e iOS, com configurações personalizáveis e vários aspectos pensados na experiência do usuário final.",
      ],
      // list: [],
    },
  },
  {
    pathname: "app-mobile-bancario-e-sistema-de-gesta-de-contas-conta-xip",
    title: "App mobile bacário e sistema de gestão de contas",
    excerpt: [
      "Nossa equipe de Outsourcing atuou no design, user experience e desenvolvimento do aplicativo mobile e no sistema de gestão de contas e usuários.",
    ],
    tags: ["React", "React Native", "Redux", "SCSS", "Serverless", "NodeJS", "MongoDB"],
    cta: "Veja o case",
    logo: clientContaXip,
    logoTitle: "Conta Xip",
    cover: coverContaXip,
    coverTitle: "Case Conta Xip",
    caseImg: caseContaXip,
    caseImgTitle: "Resultado do Case Conta Xip",
    info: [
      {
        title: "Expertise",
        list: [
          "Frontend",
          "Backend",
          "Testes QA",
        ],
      },
      {
        title: "Setor",
        list: ["Bancário"],
      },
      {
        title: "Modelo",
        list: ["Aplicação Web", "App Mobile"],
      },
    ],
    content: [
      {
        // figure: "",
        // figureTitle: "",
        title: "O desafio",
        text: [
          "Com a intenção de levar modernização aos clientes da Solar, foi criada a Conta XIp, que  nos apresentou seu planejamento estratégico e análise de mercado, quando precisou desenvolver seu novo Aplicativo de pagamentos e um backoffice robusto o suficiente para complementar essa gestão.",
          "Como se tratava de um projeto de longa duração, indicamos o uso de nosso plano Outsourcing com uma equipe inicial de desenvolvimento e posteriormente uma equipe de sustentação do produto. Por se tratar de um serviço bancário, investigamos todas as necessidades e regulamentações que seriam necessárias para atender às demandas do Banco Central.",
          "Como desenvolvemos o produto de ponta a ponta, iniciamos com análises técnicas e de experiência de uso, criamos diferentes fluxos, protótipos e documentação de regras de negócio e requisitos funcionais.",
        ],
        // list: [],
      },
      {
        // figure: "",
        // figureTitle: "",
        title: "Nossa abordagem",
        // text: [],
        list: [
          "Análise de experiência de usuário",
          "Análise técnica",
          "Criação de protótipos",
          "Desenvolvimento Nativo",
          "Desenvolvimento Web",
          "Infraestrutura e arquitetura",
          "Implementação e validação",
          "Evolução contínua",
        ],
      },
    ],
    results: {
      title: "Resultado",
      text: [
        "Nossa equipe de Outsourcing atuou na experiência e desenvolvimento dos aplicativos mobile para Android e iOS e também no sistema de gestão e aprovação de contas e usuários.",
        "Criamos um ecossistema capaz de controlar cada detalhe das operações e potencializar o acesso a uma plataforma poderosa e ainda assim simples e amigável, sendo capaz de entregar diversas modalidades de serviços bancários aos clientes Solar."
      ],
      // list: [],
    },
  },
  {
    pathname: "aplicacao-web-para-personalizacao-de-camisetas-amc",
    title: "Aplicação web para personalização de camisetas",
    excerpt: [
      "Ajudamos a AMC desenvolvendo uma aplicação web de customização de camisetas da Colcci, permitindo que os usuários escolham suas estampas.",
    ],
    tags: ["React", "NodeJS"],
    cta: "Veja o case",
    logo: clientAmc,
    logoTitle: "AMC",
    cover: coverAmcEuqfiz,
    coverTitle: "Case AMC",
    caseImg: caseAmcEuqfiz,
    caseImgTitle: "Resultado do Case AMC",
    info: [
      {
        title: "Expertise",
        list: [
          "UX/UI",
          "Frontend",
          "Backend",
          "Testes de QA",
          "Infra",
        ],
      },
      {
        title: "Setor",
        list: [
          "Roupas",
          "E-commerce",
        ],
      },
      {
        title: "Modalidade",
        list: [
          "Desenvolvimento Web",
          "Integração entre sistemas",
        ],
      },
    ],
    content: [
      {
        // figure: "",
        // figureTitle: "",
        title: "O desafio",
        text: [
          "A AMC precisava de uma solução para personalizar as camisetas da Colcci, vendidas no site da empresa. O aplicativo tinha que estar disponível em totens usados em grandes eventos e feiras comerciais, mas também eram necessários links diretos para a plataforma de e-commerce.",
          "Por trás da aplicação, também precisaria haver uma maneira de a equipe de fabricação acessar os pedidos e as instruções personalizadas para fazer a camiseta de acordo com o pedido.",
          "Após esse processo, também seria necessário que a AMC retornasse todas as informações e status padrão para que os clientes não tivessem que acessar duas plataformas e criar contas em sistemas diferentes para realizar esse processo.",
        ],
        // list: [],
      },
      {
        // figure: "",
        // figureTitle: "",
        title: "Nossa abordagem",
        // text: [],
        list: [
          "Análise do plano de negócio",
          "Análise da experiência do usuário",
          "Desenvolvimento do protótipo",
          "Definição da stack de desenvolvimento",
          "Desenvolvimento e testes",
          "Implementação e suporte de infraestrutura",
        ],
      },
    ],
    results: {
      title: "Resultado",
      text: [
        "Começando pela análise das solicitações do cliente, identificamos que seria necessário o desenvolvimento de duas aplicações e uma API de integração.",
        "A primeira aplicação garante que os clientes da Collci possam fazer a customização do produto e, por meio da API que desenvolvemos, os dados necessários para a personalização seriam enviadas diretamente a fábrica.",
        "No final dessa etapa o cliente da Collci tem o seu produto customizado enviado diretamente para o carrinho de compras na plataforma de e-commerce, tornando essa experiência muito mais fluida.",
        "A segunda aplicação recebe os pedidos e permite que a equipe de confecção tenha acesso, por meio de login e senha, ao gerenciamento, produzindo-os de acordo com os parâmetros enviados no pedido.",
        "Fizemos com que a aplicação gerasse os arquivos finais de acordo com as especificações da fábrica e que os relatórios de status de produção seja gerenciados pelo departamento de produto.",
      ],
      // list: [],
    },
  },
  {
    pathname: "desenvolvimento-do-pix-bass-bpp",
    title: "Desenvolvimento do Pix BAAS",
    excerpt: [
      "Disponibilizamos para os clientes BPP um ambiente de homologação e produção dentro das regulamentações do Banco Central.",
    ],
    tags: ["Java"],
    cta: "Veja o case",
    logo: clientBpp,
    logoTitle: "BPP",
    cover: coverBppPix,
    coverTitle: "Case BPP",
    caseImg: caseBppPix,
    caseImgTitle: "Resultado do Case BPP",
    info: [
      {
        title: "Expertise",
        list: [
          "Backend",
          "Devops",
          "Testes de QA",
        ],
      },
      {
        title: "Setor",
        list: ["Bancário"],
      },
      {
        title: "Modalidade",
        list: ["Desenvolvimento de API"],
      },
    ],
    content: [
      {
        // figure: "",
        // figureTitle: "",
        title: "O desafio",
        text: [
          "As novas APIs de pagamento e código QR do Banco Central abriram novas possibilidades para a BPP.",
          "Para aproveitar essas oportunidades, era necessário fazer alterações em sua própria API. Como a BPP oferece uma ampla gama de serviços de tecnologia financeira e tem uma gama ainda maior de grandes clientes, era necessário que essas adaptações cumprissem rigorosamente as novas solicitações do Banco Central.",
          "Além disso era necessário trabalhar em novas integrações relacionadas ao novo método de pagamento no Brasil, o Pix.",
        ],
        // list: [],
      },
      {
        // figure: "",
        // figureTitle: "",
        title: "Nossa abordagem",
        // text: [],
        list: [
          "Análise de negócios",
          "Análise técnica",
          "Gerenciamento de informações",
          "Gestão de projeto",
          "Desenvolvimento ágil",
          "Testes de adequação",
          "Implementação e validação",
        ],
      },
    ],
    results: {
      title: "Resultado",
      text: [
        "Nossa equipe de outsourcing realizou todas as novas adequações solicitadas pelo Banco Central, quando tivemos de mudar a versão das API's de Pagamento e QRCode e também nas implementações do Pix Saque, Pix Troca e Pix Agendamento.",
        "Com isso tivemos a oportunidade de disponibilizamos para a BPP, um ambiente de homologação e produção onde todos os testes de integração podem ser realizados antes de serem publicados oficialmente pelos clientes da nova API atualizada.",
      ],
      // list: [],
    },
  },
  {
    pathname: "sistema-de-gestao-e-teste-de-apis-bpp",
    title: "Sistema de gestão e testes de APIs",
    excerpt: [
      "Desenvolvemos uma plataforma com opção de login, documentação por API e área para testes ligados ao ambiente de homologação.",
    ],
    tags: ["Serverless", "React", "C#", "SCSS"],
    cta: "Veja o case",
    logo: clientBpp,
    logoTitle: "BPP",
    cover: coverBppSandbox,
    coverTitle: "Case BPP",
    caseImg: caseBppSandbox,
    caseImgTitle: "Resultado do Case BPP",
    info: [
      {
        title: "Expertise",
        list: [
          "Frontend",
          "Backend",
          "Testes QA",
        ],
      },
      {
        title: "Setor",
        list: ["Bancário"],
      },
      {
        title: "Modalidade",
        list: ["Desenvolvimento de API"],
      },
    ],
    content: [
      {
        // figure: "",
        // figureTitle: "",
        title: "O desafio",
        text: [
          "A BPP nos apresentou um ambiente para validar as APIs e operações que fazia os testes das operações antes que os desenvolvedores de fato submetessem os códigos.",
          "Optamos por direcionar nossa equipe de Outsoucing para a finalização desse projeto junto a equipe da BPP.",
          "O produto estava em estágios iniciais e fizemos uma avaliação de tudo o que estava sendo feito e avaliamos o que de fato precisávamos corrigir e finalizar.",
          "Durante esse processo fizemos os ajustes necessários e tivemos a oportunidade de propor melhorias e novas implementações tanto na interface quanto nas funcionalidades da plataforma.",
        ],
        // list: [],
      },
      {
        // figure: "",
        // figureTitle: "",
        title: "Nossa abordagem",
        // text: [],
        list: [
          "Análise técnica",
          "Criação de protótipos",
          "Desenvolvimento web",
          "Implementação e validação",
        ],
      },
    ],
    results: {
      title: "Resultado",
      text: [
        "A equipe de Outsourcing criou uma plataforma de testes em que os desenvolvedores Bpp pudessem executar operações nas API's desejadas sem interferir no ambiente de produção",
        "De forma simples e intuitiva, disponibilizamos uma plataforma com sistema de controle de usuários por login, uma área específica de documentação para cada API e a área de testes ligada diretamente ao ambiente de homologação, que reduz os riscos e ainda facilita a visualização dos dados esperados para cada operação.",
      ],
      // list: [],
    },
  },
  {
    pathname: "plataforma-de-gestao-para-agricultura-familiar-suma",
    title: "Plataforma da gestão para agricultura familiar",
    excerpt: [
      "Desenvolvemos um sistema vasto, capaz de cadastrar varios tipos de usuários e produtos, além de fazer a gestão de estoque e os pontos de distribuição.",
    ],
    tags: ["React", "SCSS", "Serverless", "NodeJS", "MongoDB"],
    cta: "Veja o case",
    logo: clientSuma,
    logoTitle: "Sumá",
    cover: coverSuma,
    coverTitle: "Case Sumá",
    caseImg: caseSuma,
    caseImgTitle: "Resultado do Case Sumá",
    info: [
      {
        title: "Expertise",
        list: [
          "UX/UI Design",
          "Frontend",
          "Backend",
          "Testes QA",
        ],
      },
      {
        title: "Setor",
        list: ["Agricultura"],
      },
      {
        title: "Modelo",
        list: ["Aplicação Web"],
      },
    ],
    content: [
      {
        // figure: "",
        // figureTitle: "",
        title: "O desafio",
        text: [
          "Em busca de uma alternativa menos burocrática e com a intenção de levar modernidade para a agricultura familiar, a Sumá nos buscou com a intenção de criar uma aplicação capaz de gerir o cadastro dos usuários e a manutenção dos diversos produtos do setor agro, com isso, gerando predição de cultivos futuros.",
          "Além disso, o sistema deveria acrescentar uma camada de segurança ao mesmo tempo que cria canal facilitador entre o agricultor familiar e o mercado, de forma a reduzir várias etapas burocráticas envolvidas nessa negociação no modelo tradicional.",
          "Fizemos vários estudos junto a Sumá na busca para entender as deficiências do setor, entender as necessidades dos usuários e com isso elaborar estratégias de negócio, requisitos técnicos, fluxos e protótipos para comprovar nossas hipóteses levantadas durante nossas análises.",
        ],
        // list: [],
      },
      {
        // figure: "",
        // figureTitle: "",
        title: "Nossa abordagem",
        // text: [],
        list: [
          "Jornada e Experiência de usuário",
          "Análise técnica",
          "Criação de protótipos",
          "Desenvolvimento Web",
          "Infraestrutura e arquitetura",
          "Implementação e validação",
        ],
      },
    ],
    results: {
      title: "Resultado",
      text: [
        "Conseguimos desenvolver um sistema vasto, que permite o cadastro de produtores, extensionistas, operadores, clientes, produtos e faz a gestão do estoque e dos pontos de distribuição.",
        "Com esse sistema completo, a Sumá é capaz de reduzir vários contratempos e burocracias que intervem na facilidade da vida do pequeno agricultor familiar, dessa forma, permitindo que haja maior lucro na venda de seus produtos.",
      ],
      // list: [],
    },
  },
  {
    pathname: "sistema-de-gestao-veterinario-para-equinos-fysight",
    title: "Sistema de gestão veterinário para equinos",
    excerpt: [
      "Através da consultoria em design, user experience e desenvolvimento, entregamos uma aplicação ideal para as necessidades dos usuários.",
    ],
    tags: ["React", "Firebase"],
    cta: "Veja o case",
    logo: clientFysight,
    logoTitle: "Fysight",
    cover: coverFysight,
    coverTitle: "Case Fysight",
    caseImg: caseFysight,
    caseImgTitle: "Resultado do Case Fysight",
    info: [
      {
        title: "Expertise",
        list: [
          "Frontend",
          "Backend",
          "Testes QA",
        ],
      },
      {
        title: "Setor",
        list: ["Veterinário"],
      },
      {
        title: "Modalidade",
        list: ["Aplicação Web"],
      },
    ],
    content: [
      {
        // figure: "",
        // figureTitle: "",
        title: "O desafio",
        text: [
          "A Fysight, uma empresa que faz desenvolvimento de softwares no exterior, contratou nossos serviços de Outsourcing para adicionar mais produtividade em sua equipe interna para atender esse cliente. O projeto em questão é um sistema de gestão veterinário completo para equinos.",
          "Um sistema que necessitaria de controle de usuários para controlar o cadastro de clientes, médicos veterinários e os próprios administradores do sistema. Cada novo animal seria cadastrado com um vínculo a um proprietário específico, além de manter um inventário de cada tratamento que o animal passou ou passaria.",
          "Dessa forma, cada novo tratamento disponibilizado seria adicionado a plataforma, de forma que o produto seria cada vez mais robusto e completo.",
        ],
        // list: [],
      },
      {
        // figure: "",
        // figureTitle: "",
        title: "Nossa abordagem",
        // text: [],
        list: [
          "Análise técnica",
          "Análise de protótipos",
          "Desenvolvimento web",
          "Implementação e validação",
        ],
      },
    ],
    results: {
      title: "Resultado",
      text: [
        "Nesse projeto Outsourcing nosso time desenvolveu uma aplicação junto a Fysight, para  gestão veterinária de equinos.",
        "Fizemos a consultoria em design de experiência, interface, e o desenvolvimento em nossa stack, sendo capazes de entregar uma aplicação ideal para as necessidades dos usuários, além de contar com uma arquitetura que entrega uma performance excepcional.",
      ],
      // list: [],
    },
  },
  {
    pathname: "visualizador-de-dados-geo-demograficos-linkmesh",
    title: "Visualizador de dados geo demográficos",
    excerpt: [
      "Através de P&D, consultorias em tecnologia, experiência de usuário e design, criamos um sistema robusto que exibe dados geo demográficos.",
    ],
    tags: ["React", "SCSS", "Serverless", "NodeJS", "Leaflet", "Maps", "MongoDB"],
    cta: "Veja o case",
    logo: clientLinkmesh,
    logoTitle: "Linkmesh",
    cover: coverLinkmesh,
    coverTitle: "Case Linkmesh",
    caseImg: caseLinkmesh,
    caseImgTitle: "Resultado do Case Linkmesh",
    info: [
      {
        title: "Expertise",
        list: [
          "Frontend",
          "Backend",
          "Testes QA",
        ],
      },
      {
        title: "Setor",
        list: ["Agricultura"],
      },
      {
        title: "Modelo",
        list: ["Aplicação Web"],
      },
    ],
    content: [
      {
        // figure: "",
        // figureTitle: "",
        title: "O desafio",
        text: [
          "Imagine a possibilidade de contar com um estudo geo demográfico na sua região, capaz de indicar pontos de interesse no seu bairro, capaz de permitir, por exemplo, saber qual o melhor lugar para construir um hospital, ou como redirecionar o fluxo de uma rua para melhorar engarrafamentos na cidade.",
          "Esse é o propósito do Linkmesh, uma plataforma de estudos demográficos com visualização geográfica por tipo de demanda de acordo com o estudo requerido. De forma automatizada e com dados em tempo real, um analista demográfico consegue extrair informações pertinentes ao seu estudo e com isso criar hipóteses e também predições exponencialmente acuradas de acordo com o que planeja.",
        ],
        // list: [],
      },
      {
        // figure: "",
        // figureTitle: "",
        title: "Nossa abordagem",
        // text: [],
        list: [
          "Experiência de Usuário",
          "Análise técnica",
          "Criação de protótipos",
          "Desenvolvimento Web",
          "Infraestrutura e arquitetura",
          "Implementação e validação",
          "Evolução de produto",
        ],
      },
    ],
    results: {
      title: "Resultado",
      text: [
        "Com muita pesquisa e desenvolvimento, consultoria em tecnologia, consultoria em experiência de usuário e design, fomos capazes de criar um sistema robusto que imprime diversos dados geo demográficos e permite ao usuário salvar diversas visualizações e estudos de área de interesse.",
      ],
      // list: [],
    },
  },
  // {
  //   pathname: "aplicacao-web-para-evento-online-yellow-panda",
  //   title: "Aplicação web para evento online",
  //   excerpt: [
  //     "Junto a Yellow Pand e a TM1, desenvolvemos uma plataforma de interação para o público infantil da Netflix.",
  //   ],
  //   tags: ["React", "NodeJS", "AWS"],
  //   cta: "Veja o case",
  //   logo: clientYellowPanda,
  //   logoTitle: "Yellow Panda",
  //   cover: coverYellowPanda,
  //   coverTitle: "Case Yellow Panda",
  //   caseImg: caseYellowPanda,
  //   caseImgTitle: "Resultado do Case Yellow Panda",
  //   info: [
  //     {
  //       title: "Expertise",
  //       list: [
  //         "Frontend",
  //         "Backend",
  //         "Testes QA",
  //       ],
  //     },
  //     {
  //       title: "Setor",
  //       list: ["Eventos"],
  //     },
  //     {
  //       title: "Tipo",
  //       list: ["Aplicação Web"],
  //     },
  //   ],
  //   content: [
  //     {
  //       figure: "",
  //       figureTitle: "",
  //       title: "O desafio",
  //       text: [],
  //       list: [],
  //     },
  //     {
  //       figure: "",
  //       figureTitle: "",
  //       title: "Nossa abordagem",
  //       text: [],
  //       list: [],
  //     },
  //   ],
  //   results: {
  //     title: "Resultado",
  //     text: [],
  //     list: [],
  //   },
  // },
];

export default casePost;
