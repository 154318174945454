const caseHeaderFigure = `${process.env.assetsDomain}/assets/images/cases-header.jpg`;

const casesData = {
  header: {
    figure: "",
    figureTitle: "Nossos cases",
    title: "Encorajamos o uso da tecnologia",
    subtitle: "Nosso proposito é promové-la como algo que ",
    subtitleEmphasis: "traz conhecimento aos mais diversos lugares ",
    subtitleEmphasisEnd: "para que as pessoas e organizações possam se conectar.",
  },
}

export default casesData;