import { Fragment, useState } from "react";
import { Link, useParams } from "react-router-dom";

import Footer from "@components/Footer";
import Logo from "@components/Logo";
import Menu from "@components/Menu";

import servicePost from "@data/post/service";

const Service = () => {
  const { serviceId } = useParams();
  const service = servicePost.find((service) => service.pathname === serviceId);
  const isEven = (n) => {
    return n % 2 === 0;
  };
  const isOdd = (n) => {
    return n % 2 !== 0;
  };
  const [currentPlan, setCurrentPlan] = useState("");
  const [count, setCount] = useState(0);
  const [uniques, setUniques] = useState([]);
  const [desiredTeam, setDesiredTeam] = useState([]);
  const [roleToAdd, setRoleToAdd] = useState("");

  const checkUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const choosePlan = (card) => {
    setCurrentPlan(card);
    const temp = new Array();
    card.list.forEach((i) => {
      if (i.includes("x)")) {
        const xIndex = i.indexOf("x)");
        const role = i.slice(0, xIndex - 3);
        const count = i.slice(xIndex - 1, xIndex);
        for (var i = 0; i < count; i++) temp.push(role);
      } else {
        temp.push(i);
      }
    });
    const tempUniques = temp.filter(checkUnique);
    setUniques(tempUniques);
    setDesiredTeam(temp);
  };

  const addRole = () => {
    const tempDesired = desiredTeam;
    const tempUniques = uniques;
    tempDesired.push(roleToAdd);
    if (!tempUniques.includes(roleToAdd)) {
      tempUniques.push(roleToAdd);
    }
    setDesiredTeam(tempDesired);
    setUniques(tempUniques);
  };

  const deleteMember = (member) => {
    let tempArray = desiredTeam;
    let tempUniques = uniques;
    tempArray.splice(tempArray.indexOf(member), 1);
    if (!tempArray.includes(member)) {
      tempUniques.splice(tempUniques.indexOf(member), 1);
      setUniques(tempUniques);
    }
    setDesiredTeam(tempArray);
    setCount(count + 1);
  };
  const lastDisplay = service.content.length - 1;

  return (
    <section data-page="service">
      <Menu />

      <section id="header">
        <figure id="header-background" aria-hidden={true}></figure>
        <div className="container">
          <header className="flex justify-content-center">
            <Logo />
          </header>
          <hgroup id="header-title">
            <h1>{service.title}</h1>
            {service.subtitle && (
              <h2>
                {service.subtitle}
                {service.subtitleEmphasis && (
                  <em>{service.subtitleEmphasis}</em>
                )}
                {service.subtitleEmphasisEnd && (
                  <>{service.subtitleEmphasisEnd}</>
                )}
              </h2>
            )}
          </hgroup>
        </div>
      </section>

      <section className="section padding-vertical-80 padding-vetical-120-tablet">
        <div
          className="section-background"
          aria-hidden={true}
        ></div>
        <div className="container">
          <div className="grid columns-2-laptop gap-40 gap-80-laptop gap-120-laptop-l">
            {service.content.map((content, index) => (
              <div key={index}>
                <hgroup className="section-title">
                  <h2>{content.title}</h2>
                </hgroup>
                <article className="section-content margin-top-40">
                  <div className="code">
                    <div className="code-ruler" aria-hidden={true}>
                      <div className="ruler-inline" aria-hidden={true}></div>
                    </div>
                    {content.text && (
                      <div className="code-area">
                        {content.text.map((paragraph, index) => (
                          <p key={index}>{paragraph}</p>
                        ))}
                      </div>
                    )}
                  </div>
                </article>
              </div>
            ))}
          </div>
        </div>
      </section>

      {service.plans && (
        <section className="section padding-vertical-80 padding-vetical-120-tablet">
          <div
            className="section-background"
            aria-hidden={true}
          ></div>
          <div className="container">
            {currentPlan === "" && (
              <div className="grid columns-3-laptop gap-40 gap-80-laptop">
                {service.plans.cards.map((card, index) => (
                  <div
                    className="flex direction-column justify-content-space-between"
                    key={index}
                  >
                    <div>
                      <hgroup className="section-title">
                        <h3>{card.title}</h3>
                      </hgroup>
                      <article className="section-content margin-top-24">
                        <div className="code">
                          <div className="code-ruler" aria-hidden={true}>
                            <div
                              className="ruler-inline"
                              aria-hidden={true}
                            ></div>
                          </div>
                          <div className="code-area">
                            <p>{card.text}</p>
                          </div>
                        </div>
                        {card.list && (
                          <ul className="margin-top-24">
                            {card.list.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        )}
                        {card.benefits && (
                          <ul className="margin-top-24">
                            {card.benefits.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        )}
                      </article>
                    </div>
                    {/* <button
                      className="button button-accent margin-top-56"
                      onClick={() => choosePlan(card)}
                    >
                      <i
                        className="icon icon-angle-right margin-right-4"
                        aria-hidden={true}
                      />
                      <span>{card.toggle}</span>
                    </button> */}
                  </div>
                ))}
              </div>
            )}
            {/* {currentPlan && (
              <div className="grid columns-3-laptop gap-40 gap-80-laptop">
                <div>
                  <hgroup className="section-title">
                    <h3>{currentPlan.title}</h3>
                  </hgroup>
                  <article className="section-content margin-top-24">
                    <div className="code margin-top-24">
                      <div className="code-ruler" aria-hidden={true}>
                        <div className="ruler-inline" aria-hidden={true}></div>
                      </div>
                      <div className="code-area">
                        <p>{currentPlan.text}</p>
                      </div>
                    </div>
                    {currentPlan.list && (
                      <ul className="margin-top-24">
                        {currentPlan.list.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    )}
                    {currentPlan.benefits && (
                      <ul className="margin-top-24">
                        {currentPlan.benefits.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    )}
                  </article>
                  <button
                    className="button button-accent margin-top-56"
                    onClick={() => {
                      setCurrentPlan("");
                      setDesiredTeam([]);
                      setUniques([]);
                    }}
                  >
                    <i
                      className="icon icon-angle-left margin-right-4"
                      aria-hidden={true}
                    />
                    <span>{currentPlan.backLabel}</span>
                  </button>
                </div>
                <div className="column-span-2-laptop">
                  <form id="plans-form" action="" className="form">
                    {service.plans.form.filedsets.map((fieldset, index) => (
                      <fieldset key={index}>
                        <h4 className="form-title">{fieldset.title}</h4>
                        <div
                          className={`grid ${
                            fieldset.columns
                              ? `columns-${fieldset.columns}-${fieldset.columnsBreakpoint}`
                              : ""
                          } gap-16 margin-top-24`}
                        >
                          {fieldset.inputs.map((input, index) => {
                            let component;
                            if (input.type === "select") {
                              component = (
                                <select
                                  id={input.id}
                                  name={input.name}
                                  required={input.required}
                                  key={index}
                                >
                                  {input.options.map((option, index) => (
                                    <option value={option.value} key={index}>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              );
                            } else if (input.type === "textarea") {
                              component = (
                                <textarea
                                  id={input.id}
                                  name={input.name}
                                  required={input.required}
                                ></textarea>
                              );
                            } else {
                              component = (
                                <input
                                  id={input.id}
                                  name={input.name}
                                  type={input.type}
                                  required={input.required}
                                />
                              );
                            }
                            return (
                              <Fragment key={index}>
                                {input.divider && (
                                  <div
                                    className={`divider ${
                                      input.dividerSpan
                                        ? `column-span-${input.dividerSpan}-${input.dividerBreakpoint}`
                                        : ""
                                    }`}
                                    aria-hidden={true}
                                  ></div>
                                )}
                                <label
                                  className={`${
                                    input.columnSpan
                                      ? `column-span-${input.columnSpan}-${input.columnSpanBreakpoint}`
                                      : ""
                                  }`}
                                >
                                  <span>{input.label}</span>
                                  {component}
                                </label>
                              </Fragment>
                            );
                          })}
                        </div>
                      </fieldset>
                    ))}
                    {service.plans.form.team && (
                      <fieldset>
                        <h4 className="form-title">
                          {service.plans.form.team.title}
                        </h4>
                        <div className="grid gap-16 margin-top-32">
                          {desiredTeam.length != 0 &&
                            uniques.map((member, index) => {
                              const count = desiredTeam.filter(
                                (i) => i === member,
                              ).length;
                              return (
                                <div
                                  key={index}
                                  className="flex align-center justify-content-space-between padding-vertical-4"
                                >
                                  <span>
                                    {member} {count != 1 && `(${count}x)`}
                                  </span>
                                  <button
                                    className="button button-remove margin-left-16"
                                    onClick={() => {
                                      deleteMember(member);
                                    }}
                                  >
                                    <i
                                      className="icon icon-trash"
                                      aria-hidden={true}
                                    />
                                  </button>
                                </div>
                              );
                            })}
                          <div className="divider" aria-hidden="true" />
                          <label>
                            <span>
                              Add {desiredTeam.length > 0 && "more"} positions
                            </span>
                            <div className="flex align-center">
                              <select
                                onChange={(e) => setRoleToAdd(e.target.value)}
                                defaultValue={""}
                              >
                                <option value="">Select</option>
                                {service.plans.form.team.roles.map(
                                  (role, index) => (
                                    <option value={role} key={index}>
                                      {role}
                                    </option>
                                  ),
                                )}
                              </select>
                              <button
                                className="button button-add margin-left-24"
                                disabled={roleToAdd === ""}
                                onClick={() => {
                                  addRole();
                                  setCount(count + 1);
                                }}
                              >
                                <i
                                  className="icon icon-light-plus-circle icon-width-32"
                                  aria-hidden={true}
                                />
                              </button>
                            </div>
                          </label>
                        </div>
                      </fieldset>
                    )}
                    <button className="button button-accent margin-top-56">
                      <i
                        className="icon icon-angle-right margin-right-4"
                        aria-hidden={true}
                      />
                      <span>{service.plans.form.submit}</span>
                    </button>
                  </form>
                </div>
              </div>
            )} */}
          </div>
        </section>
      )}

      <section className="section padding-vertical-80 padding-vetical-120-tablet">
        <div
          className="section-background gradient-bottom"
          aria-hidden={true}
        ></div>
        <div className="container">
          <Link
            to="/servicos"
            className="button button-accent margin-top-56"
          >
            <i
              className="icon icon-angle-left margin-right-4"
              aria-hidden={true}
            />
            <span>Voltar aos serviços</span>
          </Link>
        </div>
      </section>

      <Footer />
    </section>
  );
};

export default Service;
