const servicePost = [
  {
    pathname: "outsourcing-de-desenvolvimento",
    // figure: "",
    // figureTitle: "",
    icon: "user-plus",
    title: "Outsourcing de desenvolvimento",
    subtitle: "Com nossos times multidiciplinares, você alcança seus objetivos em pouco tempo.",
    excerpt: [
      "Para atuar ou complementar o desenvolvimento e gerenciamento de seu projeto, nos selecionamos times, que podem variar em tamanho, de acordo com suas necessidades.",
    ],
    cta: "Saiba mais",
    content: [
      {
        // figure: "",
        // figureTitle: "",
        title: "Como funciona?",
        text: [
          "O outsourcing de desenvolvimento é uma solução completa para suas necessidades de desenvolvimento e gerenciamento de produtos. Nós fornecemos uma gama completa de serviços de desenvolvimento de software. Sabia que cerca de 38% das empresas buscam por outsourcing de desenvolvimento para melhorar a eficiência e reduzir custos?",
          "Empresas em todo o mundo estão aproveitando o outsourcing para aumentar seus resultados. Ao escolher um especialista certo como a Tentaculo, você encontra prossionais bem qualificados mais rapidamente. Não faz sentido fazer algo sozinho se você pode contratar um especialista com o devido conhecimento que fará uma seleção mais rápida a um custo muito reduzido.",
          "Iremos sugerir um time ou o profissional mais adequado que se encaixe perfeitamente nas suas necessidades e orçamento. Assim, você pode relaxar enquanto assumimos o controle e tudo o que você precisa fazer é fornecer orientações do produto.",
        ]
      },
      {
        // figure: "",
        // figureTitle: "",
        title: "Quais os benefícios?",
        text: [
          "",
          "Disponibilizaremos um time multidisciplinar qualificado para atuar ou complementar as suas necessidades de desenvolvimento e gestão do produto. Nossos times de designers, desenvolvedores e gestores de projeto, garantirão o sucesso dos seus projetos.",
          "Direcionamos os profissionais até você a no momentos em que você necessitar e colocamos o time em sincronia em questão de dias. Somos uma empresa totalmente independente sendo a melhor solução para minimizar seus custos com e com a responsabilidade na qualidade de tudo o que é entregue, cabendo a você apenas a responsabilidade de prover as orientações que já fazem parte da cultura da sua empresa.",
          "Oferecemos tudo o que você precisa para ter um produto de sucesso. Descubra como nosso times de Outsourcing de Desenvolvimento podem ajudá-lo a desenvolver ou melhorar seu produto."
        ]
      },
    ],
    plans: {
      pathname: "",
      title: "The right fit for your needs",
      cards: [
        {
          title: "Começe do Zero",
          text: "Esteja você iniciando o desenvolvimento de um novo produto ou aumentando uma equipe existente, nossos profissionais irão produzir código de qualidade em pouco tempo.",
          list: [
            "Arquiteto Senior",
            "Desenvolvedor Senior",
            "Desenvolvedor pleno (2x)",
          ],
          toggle: "Vamos começar",
          backLabel: "Voltar",
        },
        {
          title: "Mantenha vivo",
          text: "Tenha acesso a um time completo com conhecimento avançado para projetos já em andamento e que precisam de profissionais para manter a sustentação dos produtos.",
          list: [
            "Desenvolvedor Senior",
            "Desenvolvedor Pleno (2x)",
            "Desenvolvedor Junior",
          ],
          toggle: "Sustentar meu projeto",
          backLabel: "Voltar",
        },
        {
          title: "Deixe-o crescer",
          text: "A inovação é a chave para o crescimento da empresa. Podemos oferecer a você um time de gestores de tecnologia e desenvolvedores de software para ajudar no seu projeto, economizando tempo e diminuindo custos para o seu negócio.",
          list: [],
          benefits: [
            "Pesquise e desenvolva",
            "Escale de forma estável e atualizada",
            "Evolua seu negócio",
          ],
          toggle: "Escolha o seu time",
          backLabel: "Voltar",
        },
      ],
      form: {
        filedsets: [
          {
            columns: 2,
            columnsBreakpoint: "laptop",
            title: "Contato",
            inputs: [
              {
                id: "",
                name: "name",
                label: "Nome",
                type: "text",
                required: true,
              },
              {
                id: "",
                name: "email",
                label: "E-mail",
                type: "email",
                required: true,
              },
            ],
          },
          {
            columns: 2,
            columnsBreakpoint: "tablet",
            title: "Detalhes",
            inputs: [
              {
                id: "",
                name: "",
                label: "Orçamento",
                type: "select",
                options: [
                  {
                    value: "0",
                    label: "Selecione",
                  },
                  {
                    value: "10.000.00",
                    label: "10.000.00",
                  },
                  {
                    value: "20.000.00",
                    label: "20.000.00",
                  },
                  {
                    value: "30.000.00",
                    label: "30.000.00",
                  },
                ],
                required: true,
              },
              {
                id: "",
                name: "",
                label: "Tempo estimado",
                type: "select",
                options: [
                  {
                    value: "0",
                    label: "Selecione",
                  },
                  {
                    value: "03 meses",
                    label: "03 meses",
                  },
                  {
                    value: "06 meses",
                    label: "06 meses",
                  },
                  {
                    value: "12 meses",
                    label: "12 meses",
                  },
                ],
                required: true,
              },
              {
                columnSpan: 2,
                columnSpanBreakpoint: "tablet",
                id: "",
                name: "",
                label: "Descrição",
                type: "textarea",
                divider: true,
                dividerSpan: 2,
                dividerBreakpoint: "tablet",
                required: true,
              },

            ],
          },
        ],
        team: {
          title: "Configure seu time",
          roles: [
            "Arquiteto Senio",
            "Desenvolvedor Senior",
            "Desenvolvedor Pleno",
            "Desenvolvedor Junior",
          ]
        },
        submit: "Solicite atendimento",
      }
    },
  },
  {
    pathname: "software-sob-demanda",
    // figure: "",
    // figureTitle: "",
    icon: "phone-laptop",
    title: "Software sob demanda",
    subtitle: "Somos especializados em desenvolvimento de software sob demanda e nossa principal especialidade é combinar seu projeto com a tecnologia certa.",
    excerpt: [
      "Desenvolver aplicações é um processo demorado e é difícil encontrar profissionais qualificados. Em vez de você mesmo assumir esssa fardo, deixe-nos cuidar de tudo. Vamos pesquisa, analisar, desenvolver e entregar um produto que funcione como deveria."
    ],
    cta: "Saiba mais",
    content: [
      {
        // figure: "",
        // figureTitle: "",
        title: "O que posso esperar?",
        text: [
          "Você sabe exatamente o que precisa ou deseja, mas não tem a expertise em desenvolvimento? Você conhece sua aplicação, mas está tendo doficuldade com limitações de tecnologia? Somos especialistas em desenvolvimento de software e podemos ajudar.",
          "Somos especializados em desenvolvimento de software sob demanda e iremos combinar seu projeto com a tecnologia certa. Acreditamos em um processo de desenvolvimento colaborativo e o desenvolvimento de aplicações móveis e web sob demanda é nossa principal habilidade!",
          "Nossa equipe pró-ativa é qualificada e experiente em todos os campos de desenvolvimento. Nosso compromisso com a transparência e sua satisfação nos tornará parceiros de negócios excepcionais.",
        ]
      },
      {
        // figure: "",
        // figureTitle: "",
        title: "Quais são os benefícios?",
        text: [
          "Oferecemos analise, pesquisa, design e desenvolvimento completos do início ao fim do seu projeto, tanto para aplicações web quanto móveis. Nossa equipe possui ampla experiência em todo o processo de desenvolvimento, desde o brainstorming e design até a implementação e lançamento.",
          "Você terá um gerente de projeto dedicado que manterá uma comunicação próxima com você durante todas as etapas do processo do desenvolvimento. Ele também o ajudará a escolher a melhor abordagem técnica para o seu produto, seja uma aplicação web, aplicativos móveis nativos ou um MVP.",
          "Nosso processo garante ótimos resultados. Oferecemos não apenas o melhor software, mas um processo fácil e agradável, perfeito para você que tem uma janela de tempo definida e precisa de um aplicativo mais ágil rodando em poucos meses."
        ]
      },
    ],
  },
  {
    pathname: "business-blueprint",
    // figure: "",
    // figureTitle: "",
    icon: "file-certificate",
    title: "Business blueprint",
    subtitle: "Oferecemos um plano de ação detalhado, com os melhores formatos de desenvolvimento, equipes necessárias e até valores estimados para o desenvolvimento do seu projeto.",
    excerpt: [
      "Se você tem uma ideia, mas não sabe por onde começar, ou se precisa validar uma ideia sem ter muitos custos iniciais antes de começar a modelar seu projeto da maneira certa, oferecemos um plano de ação extremamente detalhado e cunhado para atingir suas necessidades.",
    ],
    
    content: [
      {
        // figure: "",
        // figureTitle: "",
        title: "O que é um Business Blueprint?",
        text: [
          "Você tem uma ótima ideia, mas não sabe por onde começar? Ou você precisa validar uma ideia sem ter muitos custos iniciais antes de começar a modelar seu projeto da maneira certa?",
          "Criamos o serviço de Business Blueprint para empreendedores e pequenas empresas em mente. Você receberá um plano de ação extremamente detalhado, com estimativas de tempo de desenvolvimento, detalhamento de quais funções necessárias para o projeto e indicação das tecnologias certas para atingir as necessidades do seu projeto.",
        ]
      },
      {
        // figure: "",
        // figureTitle: "",
        title: "O que eu ganho com isso?",
        text: [
          "Talvez não tenha ideia de como iniciar a implementação dos seus projetos e este serviço vai ajudá-lo a dar os primeiros passos de forma correta, sem ter um grande investimento inicial e sem necessariamente precisar contratar uma equipa antes mesmo de validar a sua ideia.",
          "Com o Business Blueprint, ajudamos você a transformar sua ideia em realidade, desenhando o melhor plano de ação que contém nos minimos detalhes, tadas as informações e orçamentos detalhados, mostrando como os objetivos devem ser realizados.",
        ]
      },
    ],
    cta: "Saiba mais",
  },
]

export default servicePost;