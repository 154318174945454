import { Fragment } from "react";

import Footer from "@components/Footer";
import Logo from "@components/Logo";
import Menu from "@components/Menu";

import privacyData from "@data/pages/privacy";

const Privacy = () => {
  return (
    <section data-page="privacy">
      <section id="header">
        <figure id="header-background" aria-hidden={true}></figure>
        <div className="container">
          <header className="flex justify-content-center">
            <Logo />
          </header>
          <hgroup id="header-title">
            <h1>{privacyData.header.title}</h1>
          </hgroup>
        </div>
      </section>

      <section className="section padding-vertical-80 padding-vertical-120-laptop">
        <div
          className="section-background gradient-bottom"
          aria-hidden={true}
        ></div>
        <div className="container">
          <div className="grid columns-3-laptop gap-24 gap-40-laptop">
            {privacyData.intro && (
              <div className="column-span-2-laptop">
                <article className="section-content">
                  {privacyData.intro.text && (
                    <>
                      {privacyData.intro.text.map((paragraph, index) => (
                        <p key={index}>{paragraph}</p>
                      ))}
                    </>
                  )}
                  {privacyData.intro.list && (
                    <ul className="margin-top-16">
                      {privacyData.intro.list.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  )}
                </article>
              </div>
            )}
            {privacyData.chapters && (
              <>
                {privacyData.chapters.map((chapter, index) => (
                  <div
                    className="column-span-2-laptop margin-top-24"
                    key={index}
                  >
                    <hgroup className="section-title">
                      <h2>{chapter.title}</h2>
                    </hgroup>
                    <article className="section-content margin-top-16">
                      {chapter.content &&
                        chapter.content.map((item, index) => (
                          <Fragment key={index}>
                            {item.subtitle && (
                              <h3 className="margin-top-40">
                                <em>{item.subtitle}</em>
                              </h3>
                            )}
                            {item.text &&
                              item.text.map((paragraph, index) => (
                                <p key={index}>{paragraph}</p>
                              ))}
                            {item.list && (
                              <ul className="margin-top-8">
                                {item.list.map((item, index) => (
                                  <li key={index}>{item}</li>
                                ))}
                              </ul>
                            )}
                          </Fragment>
                        ))}
                    </article>
                  </div>
                ))}
              </>
            )}
            <div aria-hidden={true}></div>
          </div>
        </div>
      </section>

      <Footer />
      <Menu />
    </section>
  );
};

export default Privacy;
