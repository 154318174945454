const cthulhu = `${process.env.assetsDomain}/assets/images/cthulhu.png`;
const mission = `${process.env.assetsDomain}/assets/images/mission.png`;
const vision = `${process.env.assetsDomain}/assets/images/vision.png`;
const values = `${process.env.assetsDomain}/assets/images/values.png`;

const aboutData = { 
  header: {
    // figure: "",
    // figureTitle: "",
    title: "Uma software house com tentáculos",
    subtitle: "Somos cidadões do mundo, ",
    subtitleEmphasis: "estudando artes arcanas e ",
    subtitleEmphasisEnd: "criando códigos que sirvam a seu proposito",
  },
  intro: {
    figure: cthulhu,
    figureTitle: "Tentáculos... em todos os lugares",
    title: "Tentáculos... ",
    titleEmphasis: "em todos os lugares",
    text: [
      "Voce ama os antigos contos de horor de H.P. Lovecraft? Você deseja secretamente que uma de susas criaturas pudesse vir a vida, espreitando pelos cantos escuros de sua casa, e comesse todo seu dever de casa?",
      "Nos também. Por isso decidimos fazer o que fazemos",
      "A Tentaculo é uma software house baseada no Brasil, operando em todo o mundo, oferecendo soluções em larga escala, assim como os tentáculos de Cthulu, que irão ajudar você a trazer a vida qualquer ideia de software ou produto que você tenha. Iremos tomar conta de tudo, desde o Design até o desenvolvimento, e até mesmo o suporte a produto.",
      "E qual nossa especialidade? Desenvolvimento, entregue pela web! Esse modelo não apenas nos permite escalar rapidamente e prover você com um suporte de alto nível, mas também nos permite ser ambientalmente mais amigáveis do que as software houses tradicionais",
      "O tíme da Tentaculo está esperando por você. Adorariamos saber mais sobre seu proximo projeto!",
    ],
  },
  content: [
    {
      figure: mission,
      figureTitle: "A missão",
      title: "A missão",
      text: [
        "A missão da Tentaculo é atualizar sua marca com as tecnologias mais eficientes possíveis. Nosso time é composto por pessoa que amam o que fazem e encontram felcidade em fazer a sua parte para mudar o mundo.",
        "Ajudamos negócios a aumentar seu sucesso através da nossa paixão pelo desenvolvimento e nossa dedicação a cada projeto, assim podemos entregar grandes produtos, fortalecer relacionamentos, se foco para solução e trazer valor para você.",
      ],
    },
    {
      figure: vision,
      figureTitle: "Visão do que buscamos",
      title: "Visão daquilo que buscamos",
      text: [
        "Uma busca para entender a verdadeira natureza do universo e como tudo se encaixa. Somo hackers, construtores, sonhadores, quem quer ciar algo nunca antes criado.",
        "Reunindo profissionais de diversos níveis, de Junior a Senior, a Tentaculo está focada em entregar um desenvolvimento de softwares de alto valor com forte foco em aprendizado contínuo, trabalho em equipe e diversão.",
        "Nos esforçamos em criar grandes produtos, baseados no tecnologias mais atuaias, que tragam sorisos aos usuários.",
      ],
    },
    {
      figure: values,
      figureTitle: "Valores indespensáveis",
      title: "Valores indespensáveis",
      text: [
        "Nosso valores são simples: os melhores produtos vem de pessoas apaixonadas e especializadas. Esses sãos nos valores mais importantes:",
      ],
      list: [
        "Inovação acima de tudo",
        "Cultura Early Adopter",
        "Pesquisa orientada por dados",
        "Experiências de aprendizado",
        "Crescimento profissional",
      ],
    },
  ],
}

export default aboutData;