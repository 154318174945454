const menuData = {
  openLabel: "Menu",
  closeLabel: "Menu",
  nav: [
    {
      to: "/servicos",
      label: "Serviços",
    },
    {
      to: "/cases",
      label: "Cases",
    },
    {
      to: "/sobre",
      label: "Sobre",
    },
    {
      to: "/contato",
      label: "Contato",
    },
  ],
}

export default menuData;