import { Fragment } from "react";

import Footer from "@components/Footer";
import Logo from "@components/Logo";
import Menu from "@components/Menu";

import contactData from "@data/pages/contact";

const Contact = () => {
  return (
    <section data-page="contact">
      <Menu />

      <section id="header">
        <figure id="header-background" aria-hidden={true}></figure>
        <div className="container">
          <header className="flex justify-content-center">
            <Logo />
          </header>
          <hgroup id="header-title">
            <h1>{contactData.header.title}</h1>
            <h2>
              {contactData.header.subtitle}
              <em>{contactData.header.subtitleEmphasis}</em>
              {contactData.header.subtitleEmphasisEnd}
            </h2>
          </hgroup>
        </div>
      </section>

      <section className="section padding-vertical-80 padding-vertical-120-laptop">
        <div
          className="section-background gradient-bottom"
          aria-hidden={true}
        ></div>
        <div className="container">
          <div className="grid columns-3-laptop gap-24 gap-80-laptop">
            <div>
              <article className="section-content">
                <div className="code">
                  <div className="code-ruler" aria-hidden={true}>
                    <div className="ruler-inline" aria-hidden={true}></div>
                  </div>
                  {contactData.text && (
                    <div className="code-area">
                      {contactData.text.map((paragraph, index) => (
                        <p key={index}>{paragraph}</p>
                      ))}
                    </div>
                  )}
                </div>
              </article>
            </div>
            <div className="column-span-2-laptop">
              <hgroup className="section-title">
                <h4>{contactData.content.label}</h4>
              </hgroup>
              <a href={`mailto:${contactData.content.email}`} className="button button-accent margin-top-24" taget="_blank">
                <i
                  className="icon icon-angle-right margin-right-4"
                  aria-hidden={true}
                />
                <span>{contactData.content.email}</span>
              </a>
              {/* <form id="contact-form" action="" className="form">
                {contactData.form.filedsets.map((fieldset, index) => (
                  <fieldset key={index}>
                    <h4 className="form-title">{fieldset.title}</h4>
                    <div
                      className={`grid ${
                        fieldset.columns
                          ? `columns-${fieldset.columns}-${fieldset.columnsBreakpoint}`
                          : ""
                      } gap-16 margin-top-24`}
                    >
                      {fieldset.inputs.map((input, index) => {
                        let component;
                        if (input.type === "select") {
                          component = (
                            <select
                              id={input.id}
                              name={input.name}
                              required={input.required}
                              key={index}
                            >
                              {input.options.map((option, index) => (
                                <option value={option.value} key={index}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          );
                        } else if (input.type === "textarea") {
                          component = (
                            <textarea
                              id={input.id}
                              name={input.name}
                              required={input.required}
                            ></textarea>
                          );
                        } else {
                          component = (
                            <input
                              id={input.id}
                              name={input.name}
                              type={input.type}
                              required={input.required}
                            />
                          );
                        }
                        return (
                          <Fragment key={index}>
                            {input.divider && (
                              <div
                                className={`divider ${
                                  input.dividerSpan
                                    ? `column-span-${input.dividerSpan}-${input.dividerBreakpoint}`
                                    : ""
                                }`}
                                aria-hidden={true}
                              ></div>
                            )}
                            <label
                              className={`${
                                input.columnSpan
                                  ? `column-span-${input.columnSpan}-${input.columnSpanBreakpoint}`
                                  : ""
                              }`}
                            >
                              <span>{input.label}</span>
                              {component}
                            </label>
                          </Fragment>
                        );
                      })}
                    </div>
                  </fieldset>
                ))}
                <button className="button button-accent margin-top-56">
                  <i
                    className="icon icon-angle-right margin-right-4"
                    aria-hidden={true}
                  />
                  <span>{contactData.form.submit}</span>
                </button>
              </form> */}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </section>
  );
};

export default Contact;
