import { Link } from "react-router-dom";

import LogoImg from "../assets/images/tentaculo-logo.svg";

const Logo = () => {
  return (
    <Link to="/">
      <img id="logo" src={LogoImg} alt="Tentaculo" />
    </Link>
  );
};

export default Logo;
