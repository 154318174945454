const contactHeaderFigure = `${process.env.assetsDomain}/assets/images/contact-header.jpg`;

const contactData = {
  header: {
    figure: "",
    figureTitle: "Contato",
    title: "Adorariamos ouvir você",
    subtitle: "Quer esteja curioso sobre os nossos métodos, quer esteja em busca de uma equipa, ou mesmo à procura de suporte."
  },
  text: [
    "Estamos a apenas um e-mail de distância. Compartilhe mais detalhes sobre suas necessidades. Ou sua cor favorita no site. De qualquer forma, adoraríamos conversar.",
  ],
  // text: [
  //   "Estamos a apenas um clique de distância para ajudá-lo. Preencha o formulário para compartilhar mais detalhes sobre suas necessidades. Ou sua cor favorita no site. De qualquer forma, adoraríamos conversar.",
  // ],
  content: {
    label: "Envie seu e-mail para:",
    email: "contato@tentaculo.digital",
  },
  form: {
    filedsets: [
      {
        columns: 2,
        columnsBreakpoint: "laptop",
        title: "Contato",
        inputs: [
          {
            columnSpan: 2,
            columnSpanBreakpoint: "laptop",
            id: "",
            name: "name",
            label: "Seu nome",
            type: "text",
            required: true
          },
          {
            id: "",
            name: "phone",
            label: "Telefone",
            type: "text",
            required: true
          },
          {
            id: "",
            name: "email",
            label: "E-mail",
            type: "email",
            required: true
          }
        ]
      },
      {
        columns: 2,
        columnsBreakpoint: "tablet",
        title: "Detalhes",
        inputs: [
          {
            id: "",
            name: "budget",
            label: "Orçamento",
            type: "select",
            options: [
              {
                value: "",
                label: "Select"
              },
              {
                value: 10000,
                label: "10.000.00"
              },
              {
                value: 20000,
                label: "20.000.00"
              },
              {
                value: 30000,
                label: "30.000.00"
              }
            ],
            required: true
          },
          {
            id: "",
            name: "timebox",
            label: "Tempo estimado",
            type: "select",
            options: [
              {
                value: "",
                label: "Select"
              },
              {
                value: 3,
                label: "03 months"
              },
              {
                value: 6,
                label: "06 months"
              },
              {
                value: 12,
                label: "12 months"
              }
            ],
            required: true
          },
          {
            columnSpan: 2,
            columnSpanBreakpoint: "tablet",
            id: "",
            name: "description",
            label: "Descrição",
            type: "textarea",
            divider: true,
            dividerSpan: 2,
            dividerBreakpoint: "tablet",
            required: true
          }
        ]
      }
    ],
    submit: "Enviar mensagem"
  }
};

export default contactData;
