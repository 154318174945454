const copyStart = "© Tentaculo "
const copyYear = (new Date().getFullYear())
const copyEnd = " . Todos os direitos reservados."

const footer = {
  title: "Assine nossa newsletter",
  placeholder: "Deixe seu melhor e-mail aqui",
  locations: [
    "Australia",
    "Brasil",
    "Canada",
    "Estados Unidos",
    "Nova Zelandia",
  ],
  lead: "Estamos em todo lugar, somos 100% remoto",
  social: [
    {
      to: "https://www.linkedin.com/company/tentaculodigital/",
      icon: "linkedin-in",
    },
    {
      to: "https://www.instagram.com/tentaculo.digital/",
      icon: "instagram",
    },
    {
      to: "https://www.facebook.com/tentaculo.digital/",
      icon: "facebook-f",
    },
  ],
  nav: [
    {
      to: "/servicos",
      label: "Serviços",
    },
    {
      to: "/cases",
      label: "Cases",
    },
    {
      to: "/sobre",
      label: "Sobre",
    },
    {
      to: "/contato",
      label: "Contato",
    },
  ],
  copyrights: copyStart + copyYear + copyEnd,
  policy: {
    to: "/cookies-privacidade",
    label: "Cookies e Privacidade",
  },
}

export default footer