import { Link } from "react-router-dom";

import Footer from "@components/Footer";
import Logo from "@components/Logo";
import Menu from "@components/Menu";

import servicesData from "@data/pages/services";
import servicePost from "@data/post/service";

const Services = () => {
  return (
    <section data-page="services">
      <Menu />

      <section id="header">
        <figure id="header-background" aria-hidden={true}></figure>
        <div className="container">
          <header className="flex justify-content-center">
            <Logo />
          </header>
          <hgroup id="header-title">
            <h1>{servicesData.header.title}</h1>
            <h2>
              {servicesData.header.subtitle}
              <em>{servicesData.header.subtitleEmphasis}</em>
              {servicesData.header.subtitleEmphasisEnd}
            </h2>
          </hgroup>
        </div>
      </section>

      <section className="section padding-vertical-80 padding-vertical-120-tablet">
        <div className="section-background" aria-hidden={true}></div>
        <div className="container">
          <hgroup className="section-title">
            <h2>{servicesData.content.title}</h2>
          </hgroup>
          <div className="grid columns-2-tablet columns-4-laptop gap-24 gap-48-laptop margin-top-40">
            {servicePost.map((post, index) => (
              <article
                className="section-content flex direction-column justify-content-space-between"
                key={index}
              >
                <div>
                  <i
                    className={`icon icon-${post.icon} icon-width-32 background-accent`}
                    aria-hidden={true}
                  />
                  <hgroup className="section-title margin-top-16">
                    <h3 className="margin-top-0">{post.title}</h3>
                  </hgroup>
                  <p className="margin-top-24">{post.excerpt}</p>
                </div>
                <Link
                  to={post.pathname}
                  className="button button-accent margin-top-56"
                >
                  <i
                    className="icon icon-angle-right margin-right-4"
                    aria-hidden={true}
                  />
                  <span>{post.cta}</span>
                </Link>
              </article>
            ))}
          </div>
        </div>

      </section>

      <section className="section padding-vertical-80 padding-vertical-120-tablet">
        <div
          className="section-background gradient-bottom"
          aria-hidden={true}
        ></div>
        <div className="container">
          <hgroup className="section-title">
            <h2>{servicesData.expertise.title}</h2>
          </hgroup>
          <div className="grid columns-2-tablet columns-4-laptop gap-24 gap-48-laptop margin-top-40">
            {servicesData.expertise.items.map((item, index) => (
              <article className="section-content" key={index}>
                <hgroup className="section-title flex align-center">
                  <i
                    className={`icon icon-${item.icon} icon-width-24 background-accent`}
                    aria-hidden={true}
                  />
                  <h3 style={{ marginTop: 0, marginLeft: "16px" }}>
                    {item.title}
                  </h3>
                </hgroup>
                {item.list && (
                  <ul className="margin-top-24">
                    {item.list.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                )}
              </article>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </section>
  );
};

export default Services;
