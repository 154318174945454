import { useState } from "react";
import { NavLink } from "react-router-dom";

import menuData from "@data/components/menu";

const Menu = () => {
  const [menuToggle, setMenuToggle] = useState(false);

  const handleMenu = () => {
    setMenuToggle(!menuToggle);
  };

  return (
    <>
      <div id="menu">
        <button
          id="menu-toggle"
          className="flex align-center gap-4"
          onClick={handleMenu}
        >
          <span>{menuData.openLabel}</span>
          <div className="icon-shadow">
            <i className="icon icon-bars drop-shadow" aria-hidden={true}></i>
          </div>
        </button>
      </div>
      <div id="menu-modal" className={menuToggle ? "active" : undefined}>
        <button
          id="menu-modal-close"
          className="flex align-center gap-4"
          onClick={handleMenu}
        >
          <span>{menuData.closeLabel}</span>
          <div className="icon-shadow">
            <i className="icon icon-times drop-shadow" aria-hidden={true}></i>
          </div>
        </button>
        <div className="container container-small">
          <nav id="menu-nav" className="grid gap-24 justify-center">
            {menuData.nav.map((link, index) => (
              <NavLink
                to={link.to}
                className="button"
                key={index}
              >
                <span>{link.label}</span>
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
    </>
  );
};

export default Menu;
