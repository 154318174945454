import { useEffect, useState } from "react";

const Frame = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [depthNumber, setDepthNumber] = useState("0000");
  const [depthAlert, setDepthAlert] = useState("");

  const handleScroll = (e) => {
    const scrollMaxY =
      window.scrollMaxY ||
      document.documentElement.scrollHeight -
        document.documentElement.clientHeight;

    const positionPercentage = Math.floor((window.scrollY / scrollMaxY) * 100);
    const depthNumberFormatting = ("000" + 80 * positionPercentage).slice(-4);

    setScrollPosition(positionPercentage);
    setDepthNumber(depthNumberFormatting);

    if (positionPercentage < 70) setDepthAlert("");
    if (positionPercentage > 70) setDepthAlert("warning");
    if (positionPercentage > 90) setDepthAlert("danger");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section id="frame">
      <aside id="frame-ruler">
        <div
          id="frame-indicator"
          style={{ top: `${scrollPosition}%` }}
          className={depthAlert}
        >
          <i className="icon icon-solid-diamond" aria-hidden={true}></i>
          <span>{depthNumber}m</span>
        </div>
      </aside>
      <div id="frame-base">
        <div id="frame-data" className={depthAlert}>
          <span className="o2">Level O2</span>
          <span className="co2">Level CO2</span>
        </div>
        <div id="frame-audio">{/*  */}</div>
      </div>
    </section>
  );
};

export default Frame;
