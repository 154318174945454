import { Routes, Route } from "react-router-dom";

import ScrollToTop from "@components/ScrollToTop";
import Helmet from "@components/Helmet";

import Home from "@pages/home/Index";
import About from "@pages/about/Index";
import Services from "@pages/services/Index";
import Service from "@pages/services/Post";
import Cases from "@pages/cases/Index";
import Case from "@pages/cases/Post";
import Contact from "@pages/contact/Index";
import Privacy from "./pages/privacy/Index";

const App = () => {
  return (
    <>
      <Helmet />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sobre" element={<About />} />
        <Route path="/servicos" element={<Services />} />
        <Route path="/servicos/:serviceId" element={<Service />} />
        <Route path="/cases" element={<Cases />} />
        <Route path="/cases/:caseId" element={<Case />} />
        <Route path="/contato" element={<Contact />} />
        <Route path="/cookies-privacidade" element={<Privacy />} />
      </Routes>
    </>
  );
};

export default App;
