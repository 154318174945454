import Footer from "@components/Footer";
import Logo from "@components/Logo";
import Menu from "@components/Menu";

import aboutData from "@data/pages/about";

const About = () => {
  const isEven = (n) => {
    return n % 2 === 0;
  };
  const isOdd = (n) => {
    return n % 2 !== 0;
  };
  const lastSection = aboutData.content.length - 1;

  return (
    <section data-page="about">
      <Menu />

      <section id="header">
        <div id="header-background" aria-hidden={true}></div>
        <div className="container">
          <header className="flex justify-content-center">
            <Logo />
          </header>
          <hgroup id="header-title">
            <h1>{aboutData.header.title}</h1>
            <h2>
              {aboutData.header.subtitle}
              <em>{aboutData.header.subtitleEmphasis}</em>
              {aboutData.header.subtitleEmphasisEnd}
            </h2>
          </hgroup>
        </div>
      </section>

      <section className="section padding-vertical-80 padding-vetical-120-tablet gradient-top">
        <div className="section-background gradient-top" aria-hidden={true}></div>
        <div className="container">
          <div className="grid columns-2-laptop gap-24 gap-40-laptop align-center">
            <figure className="section-figure" aria-hidden={true}>
              <img src={aboutData.intro.figure} alt={aboutData.intro.figureTitle} />
            </figure>
            <div>
              <hgroup className="section-title">
                <h2>
                  {aboutData.intro.title}
                  <em>{aboutData.intro.titleEmphasis}</em>
                </h2>
              </hgroup>
              <article className="section-content margin-top-40">
                <div className="code">
                  <div className="code-ruler" aria-hidden={true}>
                    <div className="ruler-inline" aria-hidden={true}></div>
                  </div>
                  <div className="code-area">
                    {aboutData.intro.text.map((paragraph, index) => (
                      <p key={index}>{paragraph}</p>
                    ))}
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>

      {aboutData.content.map((content, index) => (
        <section
          className="section padding-vertical-80 padding-vetical-120-tablet"
          key={index}
        >
          <div className={`section-background ${index === lastSection ? 'gradient-bottom' : undefined}`} aria-hidden={true}></div>
          <div className="container">
            <div className="grid columns-2-laptop gap-24 gap-40-laptop align-center">
              <figure
                className={`${isEven(index) ? "order-1-laptop" : ""}`}
              >
                <img src={content.figure} alt={content.figureTitle} />
              </figure>
              <div>
                <hgroup className="section-title">
                  <h2>{content.title}</h2>
                </hgroup>
                <article className="section-content margin-top-40">
                  <div className="code">
                    <div className="code-ruler" aria-hidden={true}>
                      <div className="ruler-inline" aria-hidden={true}></div>
                    </div>
                    {content.text && (
                      <div className="code-area">
                        {content.text.map((paragraph, index) => (
                          <p key={index}>{paragraph}</p>
                        ))}
                      </div>
                    )}
                  </div>
                  {content.list && (
                    <ul className="margin-top-40">
                      {content.list.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  )}
                </article>
              </div>
            </div>
          </div>
        </section>
      ))}

      <Footer />
    </section>
  );
};

export default About;
