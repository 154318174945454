import { useState } from "react";
import { Link } from "react-router-dom";

import Footer from "@components/Footer";
import Frame from "@components/Frame";
import Loader from "@components/Loader";
import Logo from "@components/Logo";
import Menu from "@components/Menu";

import homeData from "@data/pages/home";

import Smoke from "@assets/images/smoke.mp4";
import Octopus from "@assets/images/octopus.png";
import Portal from "@assets/images/portal.png";

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <section data-page="home">
      {isLoading && <Loader />}
      <Menu />

      <section id="hero" className="gradient-bottom">
        <video
          id="hero-video"
          autoPlay
          playsInline
          muted
          loop
          aria-hidden={true}
        >
          <source src={Smoke} type="video/mp4" />
        </video>
        <div id="hero-background" aria-hidden={true}></div>
        <div className="container">
          <header className="flex justify-content-center">
            <Logo />
          </header>
          <hgroup id="hero-title">
            <h1>{homeData.hero.title}</h1>
            <h2>
              {homeData.hero.subtitle}
              <em>{homeData.hero.subtitleEmphasis}</em>
            </h2>
          </hgroup>
        </div>
      </section>

      <section
        className="section padding-vertical-80 padding-vertical-120-laptop"
        data-section="about"
      >
        <div
          className="section-background gradient-top"
          aria-hidden={true}
        ></div>
        <div className="container">
          <div className="grid columns-2-laptop gap-24 gap-40-laptop align-center">
            <figure className="section-figure" aria-hidden={true}>
              <img src={Octopus} alt="Octopus" />
            </figure>
            <div>
              <hgroup className="section-title">
                <h2>
                  {homeData.about.title}
                  <em>{homeData.about.titleEmphasis}</em>
                  {homeData.about.titleEnd}
                </h2>
                <h3>
                  {homeData.about.subtitle}
                  <em>{homeData.about.subtitleEmphasis}</em>
                </h3>
              </hgroup>
              <article className="section-content margin-top-40">
                <h3>
                  {homeData.about.content.title}
                  <em>{homeData.about.content.titleEmphasis}</em>
                  {homeData.about.content.titleEnd}
                </h3>
                <div className="code margin-top-24">
                  <div className="code-ruler" aria-hidden={true}>
                    <div className="ruler-inline" aria-hidden={true}></div>
                  </div>
                  <div className="code-area">
                    {homeData.about.content.text.map((paragraph, index) => (
                      <p key={index}>{paragraph}</p>
                    ))}
                  </div>
                </div>
                <Link
                  to={"/sobre"}
                  className="button button-accent margin-top-56"
                >
                  <i
                    className="icon icon-angle-right margin-right-4"
                    aria-hidden={true}
                  />
                  <span>{homeData.about.cta}</span>
                </Link>
              </article>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section padding-vertical-80 padding-vertical-120-laptop"
        data-section="services"
      >
        <div
          className="section-background gradient-bottom"
          aria-hidden={true}
        ></div>
        <div className="container">
          <div className="grid columns-2-laptop gap-24 gap-40-laptop align-center">
            <figure
              className="section-figure order-1-laptop"
              aria-hidden={true}
            >
              <img src={Portal} alt="Portal Tentacles" />
            </figure>
            <div>
              <hgroup className="section-title">
                <h2>
                  {homeData.services.title}
                  <em>{homeData.services.titleEmphasis}</em>()
                </h2>
                <h3>
                  {homeData.services.subtitle}{" "}
                  <em>{homeData.services.subtitleEmphasis}</em>
                </h3>
              </hgroup>
              <article className="section-content margin-top-40">
                <h3>
                  {homeData.services.content.title}
                  <em>{homeData.services.content.titleEmphasis}</em>
                  {homeData.services.titleEnd}
                </h3>
                <div className="code margin-top-24">
                  <div className="code-ruler" aria-hidden={true}>
                    <div className="ruler-inline" aria-hidden={true}></div>
                  </div>
                  <div className="code-area">
                    {homeData.services.content.text.map((paragraph, index) => (
                      <p key={index}>{paragraph}</p>
                    ))}
                  </div>
                </div>
                <Link
                  to="/servicos"
                  className="button button-accent margin-top-56"
                >
                  <i
                    className="icon icon-angle-right margin-right-4"
                    aria-hidden={true}
                  />
                  <span>{homeData.services.cta}</span>
                </Link>
              </article>
            </div>
          </div>
        </div>
      </section>

      <section className="section" data-section="cases">
        <figure
          className="section-background gradient-both"
          aria-hidden={true}
        ></figure>
        <div className="container container-large">
          <div className="grid columns-7-laptop columns-4-laptop-l align-center">
            <hgroup className="section-title text-center text-right-laptop column-span-2-laptop column-span-1-laptop-l">
              {homeData.cases.content.tags.map((tag, index) => (
                <h2 key={index}>{tag}</h2>
              ))}
            </hgroup>
            <figure
              className="section-figure column-span-3-laptop column-span-2-laptop-l"
              aria-hidden={true}
            ></figure>
            <div className="section-content text-center text-left-laptop column-span-2-laptop column-span-1-laptop-l">
              <h2>
                {homeData.cases.title}
                <em>{homeData.cases.titleEmphasis}</em>
              </h2>
              <Link to="/cases" className="button button-accent margin-top-56">
                <i
                  className="icon icon-angle-right margin-right-4"
                  aria-hidden={true}
                />
                <span>{homeData.cases.cta}</span>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Frame />
    </section>
  );
};

export default Home;
