const servicesHeaderFigure = `${process.env.assetsDomain}/assets/images/services-header.jpg`;

const servicesData = {
  header: {
    figure: "",
    figureTitle: "Serviços",
    title: "Um método baseado em negócios e tecnologia",
    subtitle: "Nossos métodos de análise de negócio, design e desenvolvimento são ",
    subtitleEmphasis: "flexíveis e focados ",
    subtitleEmphasisEnd: "nas necessidades de cada cliente.",
  },
  expertise: {
    title: "Nossa expertise",
    items: [
      {
        icon: "user-chart",
        title: "Negócios",
        list: [
          "Consultoria e Análise",
          "Overview de Produto",
          "Pesquisa de Mercado",
          "Design de protótipo",
          "Entendimento de Usuário",
          "Definição de Projeto",
        ]
      },
      {
        icon: "pencil-paintbrush",
        title: "Design",
        list: [
          "Design de UX",
          "Design de UI",
          "Design Interativo",
          "Feedback de Usuário",
          "Design QA",
        ]
      },
      {
        icon: "code",
        title: "Desenvolvimento",
        list: [
          "Serverless",
          "Frontend",
          "Backend",
          "Database",
          "Mobile",
          "DevOps",
          "Teste QA",
        ]
      },
      {
        icon: "folder-tree",
        title: "Pesquisa",
        list: [
          "Ciência de dados",
          "Novas Tecnologias",
          "Ferramentas",
        ]
      },
    ],
  },
  content: {
    title: "Nossos serviços",
  }
}

export default servicesData;