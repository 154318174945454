import { Link } from "react-router-dom";

import Footer from "@components/Footer";
import Logo from "@components/Logo";
import Menu from "@components/Menu";

import casesData from "@data/pages/cases";
import casePost from "@data/post/case";

const Cases = () => {
  const isEven = (n) => {
    return n % 2 === 0;
  };
  const isOdd = (n) => {
    return n % 2 !== 0;
  };
  const lastSection = casePost.length - 1;

  return (
    <section data-page="cases">
      <Menu />

      <section id="header">
        <figure id="header-background" aria-hidden={true}></figure>
        <div className="container">
          <header className="flex justify-content-center">
            <Logo />
          </header>
          <hgroup id="header-title">
            <h1>{casesData.header.title}</h1>
            <h2>
              {casesData.header.subtitle}
              <em>{casesData.header.subtitleEmphasis}</em>
              {casesData.header.subtitleEmphasisEnd}
            </h2>
          </hgroup>
        </div>
      </section>

      {casePost.map((post, index) => (
        <section
          className="section padding-vertical-80 padding-vertical-120-tablet"
          key={index}
        >
          <div
            className={`section-background ${
              index === 0 ? "gradient-top" : undefined
            } ${index === lastSection ? "gradient-bottom" : undefined}`}
            aria-hidden={true}
          ></div>
          <div className="container container-small">
            <div className="grid columns-2-laptop gap-24 gap-48-laptop align-center">
              <figure
                className={`section-figure gradient-bottom ${
                  isOdd(index) ? "order-1-laptop" : undefined
                }`}
                aria-hidden={true}
              >
                <img
                  className={`case-cover ${
                    isEven(index) ? "case-cover-even" : undefined
                  }`}
                  src={post.cover}
                  alt={post.coverTitle}
                />
                <div
                  className={`case-logo ${
                    isOdd(index) ? "case-logo-odd" : "case-logo-even"
                  }`}
                  style={{
                    maskImage: `url(${post.logo})`,
                    WebkitMaskImage: `url(${post.logo})`,
                  }}
                  aria-hidden={true}
                ></div>
              </figure>
              <div>
                <hgroup className="section-title">
                  <h2>{post.title}</h2>
                </hgroup>
                <article className="section-content margin-top-40">
                  {post.excerpt && (
                    <div className="code">
                      <div className="code-ruler" aria-hidden={true}>
                        <div className="ruler-inline" aria-hidden={true}></div>
                      </div>
                      <div className="code-area">
                        {post.excerpt.map((paragraph, index) => (
                          <p key={index}>{paragraph}</p>
                        ))}
                      </div>
                    </div>
                  )}
                  {post.tags && (
                    <div className="tags margin-top-40">
                      {post.tags.map((tag, index) => (
                        <span key={index}>{tag}</span>
                      ))}
                    </div>
                  )}
                  <Link
                    to={post.pathname}
                    className="button button-accent margin-top-56"
                  >
                    <i
                      className="icon icon-angle-right margin-right-4"
                      aria-hidden={true}
                    />
                    <span>{post.cta}</span>
                  </Link>
                </article>
              </div>
            </div>
          </div>
        </section>
      ))}

      <Footer />
    </section>
  );
};

export default Cases;
